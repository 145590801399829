import React, { useState } from 'react';
import {
  TextField, Button, Grid, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Typography
} from '@mui/material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { styled } from '@mui/system';

// Styled button with padding and gradient
const StyledButton = styled(Button)({
  background: 'linear-gradient(135deg, #4b79a1, #283e51)',
  color: '#fff',
  padding: '10px 20px',
  fontWeight: 'bold',
  '&:hover': {
    background: 'linear-gradient(135deg, #283e51, #4b79a1)',
  },
  transition: 'all 0.3s ease',
});

// Scrollable dialog content with a fixed height and padding
const StyledDialogContent = styled(DialogContent)({
  height: '60vh', // Fixed height for scrollable content
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f5f5f5',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
  padding: '16px', // Padding for content
});

// Styled form paper with padding, margins, and background color
const FormPaper = styled(Grid)({
  padding: '16px',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
  marginBottom: '24px',
});

// Styled TextField with additional spacing between label and field
const StyledTextField = styled(TextField)({
  marginBottom: '16px', // Adds space between fields

  '& label': {
    marginBottom: '8px', // Adds space between the label and the field
  },
});

const EditArticleForm = ({ article, onClose, handleUpdate }) => {
  const [formData, setFormData] = useState({
    title: article.title,
    date: article.date,
    pages: article.pages || [
      {
        references: [{ reference: '' }]  // Ensure references are initialized correctly
      },
    ],
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePageChange = (e, pageIndex) => {
    const { name, value } = e.target;
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex] = {
      ...updatedPages[pageIndex],
      [name]: value,
    };
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleSubheadingChange = (e, pageIndex, subIndex) => {
    const { name, value } = e.target;
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].subheadings[subIndex] = {
      ...updatedPages[pageIndex].subheadings[subIndex],
      [name]: value,
    };
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleMediaChange = (e, pageIndex, subIndex) => {
    const file = e.target.files[0];
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].subheadings[subIndex].newMedia = file; // Store the file to be uploaded
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleSubSubheadingChange = (e, pageIndex, subIndex, subSubIndex) => {
    const { name, value } = e.target;
    const updatedPages = [...formData.pages];
    updatedPages[pageIndex].subheadings[subIndex].subsubheadings[subSubIndex] = {
      ...updatedPages[pageIndex].subheadings[subIndex].subsubheadings[subSubIndex],
      [name]: value,
    };
    setFormData({ ...formData, pages: updatedPages });
  };

  const handleReferenceChange = (e, pageIndex, refIndex) => {
    const { value } = e.target;
    const updatedPages = [...formData.pages];

    // Update the reference string in the specific page and reference index
    updatedPages[pageIndex].references[refIndex].reference = value;

    setFormData({ ...formData, pages: updatedPages });
  };

  // Add a new reference
  const addReference = (pageIndex) => {
    const updatedPages = [...formData.pages];

    // Add a new reference with an empty string for that page
    updatedPages[pageIndex].references.push({ reference: '' });

    setFormData({ ...formData, pages: updatedPages });
  };

  // Remove a reference
  const removeReference = (pageIndex, refIndex) => {
    const updatedPages = [...formData.pages];

    // Remove the specific reference by filtering it out
    updatedPages[pageIndex].references = updatedPages[pageIndex].references.filter((_, i) => i !== refIndex);

    setFormData({ ...formData, pages: updatedPages });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Clean data to remove null or empty fields, including references
    const cleanData = {
      ...formData,
      pages: formData.pages.map(page => {
        return {
          ...page,
          summary: page.summary || '', // Replace null summary with an empty string
          mainHeading: page.mainHeading || '', // Replace null mainHeading with an empty string
          subheadings: page.subheadings.map(sub => {
            return {
              ...sub,
              title: sub.title || '', // Replace null title with an empty string
              subsubheadings: sub.subsubheadings.map(subsub => ({
                ...subsub,
                title: subsub.title || '', // Replace null subsub title with an empty string
              })),
            };
          }),
          references: page.references.filter(ref => ref.reference.trim() !== '') // Exclude empty references
        };
      }),
    };

    const data = new FormData();
    data.append('title', cleanData.title);
    data.append('date', cleanData.date);
    data.append('pages', JSON.stringify(cleanData.pages));

    // Handle file uploads
    cleanData.pages.forEach((page, pageIndex) => {
      page.subheadings.forEach((subheading, subIndex) => {
        if (subheading.newMedia) {
          data.append(`subheadingImages[${pageIndex}][${subIndex}]`, subheading.newMedia);
        }
      });
    });

    try {
      await axios.put(`/api/article/${article.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Article updated successfully!');
      handleUpdate();
    } catch (error) {
      toast.error('Error updating article.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTitle>Edit Article</DialogTitle>
      <StyledDialogContent style={{ paddingTop: '16px' }}>
        <Grid container spacing={2}>
          {/* Main Article Fields */}
          <Grid item xs={12}>
            <StyledTextField
              label="Title"
              fullWidth
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              label="Date"
              type="date"
              fullWidth
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>

          {/* Pages Section */}
          {formData.pages.map((page, pageIndex) => (
            <Grid item xs={12} key={`page-${pageIndex}`}>
              <FormPaper>
                <StyledTextField
                  label={`Page ${pageIndex + 1} Main Heading`}
                  fullWidth
                  name="mainHeading"
                  value={page.mainHeading}
                  onChange={(e) => handlePageChange(e, pageIndex)}
                  required
                />
                <StyledTextField
                  label={`Page ${pageIndex + 1} Summary`}
                  fullWidth
                  multiline
                  rows={2}
                  name="summary"
                  value={page.summary}
                  onChange={(e) => handlePageChange(e, pageIndex)}
                  required
                />

                {/* Subheadings Section */}
                {page.subheadings.map((subheading, subIndex) => (
                  <Grid container key={`sub-${pageIndex}-${subIndex}`} spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        label={`Subheading ${subIndex + 1} Title`}
                        fullWidth
                        name="title"
                        value={subheading.title}
                        onChange={(e) => handleSubheadingChange(e, pageIndex, subIndex)}
                        required
                      />
                    </Grid>

                    {/* File input for subheading media */}
                    <Grid item xs={12}>
                      <input
                        type="file"
                        name={`subheadingMedia-${pageIndex}-${subIndex}`}
                        onChange={(e) => handleMediaChange(e, pageIndex, subIndex)}
                        accept="image/*"
                      />
                      {subheading.image && (
                        <p>Current Image: {subheading.image}</p>
                      )}
                    </Grid>

                    {/* Sub-Subheadings Section */}
                    {subheading.subsubheadings.map((subSubheading, subSubIndex) => (
                      <Grid item xs={12} key={`subsub-${pageIndex}-${subIndex}-${subSubIndex}`}>
                        <StyledTextField
                          label={`Sub-Subheading ${subSubIndex + 1} Title`}
                          fullWidth
                          name="title"
                          value={subSubheading.title}
                          onChange={(e) => handleSubSubheadingChange(e, pageIndex, subIndex, subSubIndex)}
                          required
                        />
                      </Grid>
                    ))}
                  </Grid>
                ))}

                {/* References Section */}
                <Grid item xs={12}>
                  <Typography variant="h6">References</Typography>
                  {page.references && page.references.map((referenceObj, refIndex) => (
                    <Grid container key={`ref-${pageIndex}-${refIndex}`} spacing={2} alignItems="center">
                      <Grid item xs={10}>
                        <StyledTextField
                          label={`Reference ${refIndex + 1}`}
                          fullWidth
                          value={referenceObj.reference || ''}
                          onChange={(e) => handleReferenceChange(e, pageIndex, refIndex)}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton onClick={() => removeReference(pageIndex, refIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Button startIcon={<AddIcon />} onClick={() => addReference(pageIndex)}>
                    Add Reference
                  </Button>
                </Grid>
              </FormPaper>
            </Grid>
          ))}
        </Grid>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <StyledButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Save Changes
        </StyledButton>
      </DialogActions>
    </>
  );
};

export default EditArticleForm;
