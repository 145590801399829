import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Button, Dialog, IconButton, Drawer, Link as MuiLink } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Right arrow icon
import './ArticleModal.css';
import LinkIcon from '@mui/icons-material/Link';

import logo from '../../assets/logoblue.png'; // Replace with the actual path to your logo

const ArticleModal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [open, setOpen] = useState(false); // State to control the modal
  const [selectedImage, setSelectedImage] = useState(null); // State to hold the clicked image
  const [referencesVisible, setReferencesVisible] = useState(false); // Toggle state for references sidebar
  const [activePageIndex, setActivePageIndex] = useState(null); // State to track which page references to show

  useEffect(() => {
    axios.get(`/api/article/${id}`)
      .then(response => {
        setArticle(response.data);
      })
      .catch(error => console.error('Error fetching article:', error));
  }, [id]);

  const handleBackClick = () => {
    navigate('/user');  // Navigate back to the user dashboard
  };

  // Function to format the date as 'dd-Month-yyyy'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };

  // Handle modal open
  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl); // Set the image to be displayed in the modal
    setOpen(true); // Open the modal
  };

  // Handle modal close
  const handleCloseModal = () => {
    setOpen(false); // Close the modal
    setSelectedImage(null); // Clear the selected image
  };

  // Handle toggling the sidebar for references
  const handleToggleReferences = (pageIndex) => {
    if (activePageIndex === pageIndex) {
      setReferencesVisible(!referencesVisible);
    } else {
      setReferencesVisible(true);
    }
    setActivePageIndex(pageIndex);
  };

  if (!article) {
    return <Typography variant="h4" align="center">Loading...</Typography>;
  }

  const pages = article.pages || [];

  return (
    <Container className="article-modal">
      {/* First Page */}
      <Box className="first-page page">
        <Box className="header">
          <Typography variant="h4" component="h2" className="article-title">
            {article.title || 'Untitled'}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" className="article-date">
            {article.date ? formatDate(article.date) : 'Unknown Date'}
          </Typography>
        </Box>

        <Box className="logo-container">
          <img src={logo} alt="Logo" className="logo-watermark" />
        </Box>

        <Box className="article-description">
          <Typography variant="body1">
            {article.description}
          </Typography>
        </Box>

        <Box className="page-number">
          <Typography variant="body2">1</Typography>
        </Box>
      </Box>

      {/* Second and Subsequent Pages */}
      {pages.map((page, index) => (
        <Box key={index} className="second-page page" position="relative">
          <Box className="page-content">
            {/* Page Main Heading */}
            {page.main_heading && (
              <Typography variant="h5" component="h3" className="page-main-heading">
                {page.main_heading}
              </Typography>
            )}

            {/* Subheadings */}
            {page.subheadings?.length > 0 && page.subheadings.map((sub, subIndex) => (
              <React.Fragment key={subIndex}>
                <Box className="subheading-wrapper">
                  <Box className="subheading-content">
                    {sub.title && (
                      <Typography variant="h6" component="h4" className="subheading-title">
                        {sub.title}
                      </Typography>
                    )}
                  </Box>

                  {/* Image Beside Subheading */}
                  {sub.image && (
                    <img
                      src={`https://smmosaic.com/uploads/${sub.image}`}
                      alt={sub.title || 'Subheading Image'}
                      className="subheading-image"
                      onClick={() => handleOpenModal(`https://smmosaic.com/uploads/${sub.image}`)} // Open modal on click
                      style={{ cursor: 'zoom-in' }} // Change cursor to indicate clickability
                    />
                  )}
                </Box>

                {/* Subsubheadings Below Subheading */}
                {sub.subsubheadings?.length > 0 && (
                  <Box className="subsubheading-section">
                    {sub.subsubheadings.map((subsub, subsubIndex) => (
                      <Typography key={subsubIndex} variant="body1" className="subsubheading">
                        {subsub.title || 'No Content'}
                      </Typography>
                    ))}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>

          {/* Page Summary */}
          {page.summary && (
            <Typography variant="body2" className="page-summary">
              {page.summary}
            </Typography>
          )}

          {/* Page Number */}
          <Box className="page-number">
            <Typography variant="body2">{index + 2}</Typography>
          </Box>

          {/* Right Arrow Icon for References */}
          {page.references && page.references.length > 0 && (
            <IconButton
              sx={{ position: 'absolute', top: '50%', right: -18, transform: 'translateY(-50%)', color: 'red' }} // Set the color to red
              onClick={() => handleToggleReferences(index)}
            >
              <ChevronRightIcon fontSize="large" />
            </IconButton>
          )}
        </Box>
      ))}

      {/* Sidebar for References */}
      <Drawer
        anchor="right"
        open={referencesVisible}
        onClose={() => setReferencesVisible(false)}
        PaperProps={{
          sx: {
            width: '300px',
            padding: '20px',
            backgroundColor: '#1b1b1b', // Dark theme background
            color: '#f5f5f5', // Light text color
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)', // Add depth with box-shadow
          }
        }}
      >
        {/* Close Button */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6">
            References ({activePageIndex !== null && pages[activePageIndex].references ? pages[activePageIndex].references.length : 0})
          </Typography>
          <IconButton
            onClick={() => setReferencesVisible(false)}
            sx={{
              color: '#f5f5f5', // Set the color to light for visibility
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {activePageIndex !== null && pages[activePageIndex].references && pages[activePageIndex].references.length > 0 ? (
        <Box>
        {/* Render only the 'reference' field of each reference object */}
        {pages[activePageIndex].references.map((referenceObj, refIndex) => {
  const maxLength = 30; // Set the maximum number of characters to display
  const truncatedLink = referenceObj.reference.length > maxLength 
    ? `${referenceObj.reference.substring(0, maxLength)}...` 
    : referenceObj.reference;

  return (
    <Typography key={refIndex} variant="body2" sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
      <span style={{ color: '#FFD700', marginRight: '8px' }}>{refIndex + 1}.</span>
      <MuiLink
        href={referenceObj.reference} // Full URL remains intact
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: '#FFD700', // Gold color for links
          textDecoration: 'none', // Removed underline
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            color: '#FFA500', // Lighter orange on hover
          },
        }}
      >
        <LinkIcon sx={{ marginRight: '6px' }} /> {/* Icon before the link */}
        {truncatedLink} {/* Show the truncated link */}
      </MuiLink>
    </Typography>
  );
})}

      </Box>
        ) : (
          <Typography>No references available</Typography>
        )}
      </Drawer>

      {/* Modal to display clicked image */}
      <Dialog open={open} onClose={handleCloseModal} maxWidth="md">
        <Box position="relative" p={2}>
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'black', // Set close icon color to black
              zIndex: 10, // Ensure it is above all content
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Display the selected image */}
          {selectedImage && (
            <Box mt={4}> {/* Add margin-top to move image below the close button */}
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          )}
        </Box>
      </Dialog>

      {/* Back Button */}
      <Box className="back-button-container">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleBackClick} 
          startIcon={<ArrowBackIcon />} 
          className="back-button"
        >
          Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default ArticleModal;
