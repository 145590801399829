import React, { useState } from 'react';
import { Box, Typography, Modal, Backdrop, Fade, CardMedia, IconButton, Link, Button, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TalkwalkerIcon from '@mui/icons-material/TrendingUp'; // Placeholder icon for Talkwalker
import InsightsIcon from '@mui/icons-material/Insights'; // Icon for live statistics line

const TrendModal = ({ open, handleClose, trendItem }) => {
  const [showCredentials, setShowCredentials] = useState(false); // State to toggle showing/hiding credentials

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        background: 'linear-gradient(135deg, #004d00, #006600)',
        color: '#FFFFFF',
      };
    }
    if (category.includes('Regional')) {
      return {
        background: 'linear-gradient(135deg, #004d4d, #008080)',
        color: '#FFFFFF',
      };
    }
    if (category.includes('International')) {
      return {
        background: 'linear-gradient(135deg, #000000, #434343)',
        color: '#FFFFFF',
      };
    }
    return {
      background: 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)',
      color: '#1b2839',
    };
  };

  const styles = trendItem ? getCategoryStyles(trendItem.categories) : {};

  const handleToggleCredentials = () => {
    setShowCredentials((prev) => !prev); // Toggle the credentials visibility
  };

  // Handle close modal and reset credentials visibility
  const handleCloseModal = () => {
    setShowCredentials(false); // Reset the credentials visibility
    handleClose(); // Call the passed in close function
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal} // Use the new function that resets credentials
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '1000px',
            maxWidth: '95%',
            maxHeight: '90vh',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
            outline: 'none',
            overflowY: 'auto',
            background: styles.background || 'linear-gradient(135deg, #ffffff 0%, #f5f5f5 50%, #e0e0e0 100%)',
            color: styles.color || '#333',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '12px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#333',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '10px',
              border: '3px solid #333',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          {/* Close button */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: styles.color,
              transition: 'color 0.3s ease',
              '&:hover': {
                color: '#ff0000',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {trendItem && (
            <>
              <CardMedia
                component="img"
                height="400"
                image={`https://smmosaic.com/uploads/${trendItem.media.split(',')[0]}`}
                alt={trendItem.name}
                sx={{
                  borderRadius: '20px',
                  mb: 3,
                  mt: 2,
                  objectFit: 'fill',
                  width: '100%',
                }}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: styles.color,
                }}
              >
                {trendItem.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  mb: 3,
                  fontSize: '1.1rem',
                  lineHeight: 1.6,
                  color: styles.color,
                }}
              >
                {trendItem.description}
              </Typography>

              {/* Heading for Link Section with Icon */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 3,
                  mt: 2,
                  color: '#007BFF', // Unique color for visibility
                }}
              >
                <InsightsIcon
                  sx={{
                    fontSize: 30,
                    mr: 1,
                    color: '#007BFF',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: 'yellow',
                    fontSize: '1.2rem',
                    textAlign: 'center',
                  }}
                >
                  To view live statistics and sentiments of hashtags, click below:
                </Typography>
              </Box>

              {/* Link Section with Talkwalker Icon and Professional Design */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 3,
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<TalkwalkerIcon />} // Adding Talkwalker icon
                  href="https://app.talkwalker.com/app/login" // Link to Talkwalker
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    backgroundColor: '#007BFF',
                    color: '#fff',
                    textTransform: 'none',
                    borderRadius: '30px',
                    px: 4,
                    py: 1.5,
                    fontSize: '1rem',
                    fontWeight: 700,
                    transition: 'background-color 0.3s ease, transform 0.2s ease',
                    '&:hover': {
                      backgroundColor: '#0056b3',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  View on Talkwalker
                </Button>
              </Box>

              {/* Divider with Info Icon for Revealing Credentials */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  my: 3,
                  cursor: 'pointer',
                }}
                onClick={handleToggleCredentials} // Click to toggle credentials visibility
              >
                <Divider
                  sx={{
                    width: '40%',
                    backgroundColor: styles.color || '#333',
                  }}
                />
                <InfoIcon
                  sx={{
                    fontSize: 40,
                    mx: 2,
                    color: styles.color || '#333',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#ff6347',
                    },
                  }}
                />
                <Divider
                  sx={{
                    width: '40%',
                    backgroundColor: styles.color || '#333',
                  }}
                />
              </Box>

              {/* Text prompting user to click icon */}
              <Typography
                variant="body2"
                sx={{
                  color: 'White',
                  textAlign: 'center',
                  fontWeight: 600,
                  fontSize: '1rem',
                  my: 2,
                }}
              >
                Click the icon above to reveal credentials of Talkwalker
              </Typography>

              {/* Credentials Section */}
              {showCredentials && (
                <Box
                  sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: '15px',
                    backgroundColor: '#f5f5f5',
                    textAlign: 'center',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                    transition: 'all 0.3s ease-in-out',
                    width: '80%',
                    maxWidth: '500px',
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#000',
                      fontWeight: 600,
                      fontSize: '1.1rem',
                    }}
                  >
                    <strong>Username:</strong> <span style={{ color: '#007BFF' }}>sentimentanalysis14@outlook.com</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#000',
                      fontWeight: 600,
                      fontSize: '1.1rem',
                      mt: 2,
                    }}
                  >
                    <strong>Password:</strong> <span style={{ color: '#007BFF' }}>Sentiment@1981</span>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default TrendModal;
