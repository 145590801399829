import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Box, Chip, Stack, Pagination, PaginationItem, IconButton, TextField, Button, Paper, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { TrendingUp as TrendingUpIcon, MoreVert, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';
import EditTrendForm from './EditTrendForm';  // Import the form

const TrendAdminPage = () => {
  const [trendsItems, setTrendsItems] = useState([]);
  const [filteredTrendsItems, setFilteredTrendsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrendId, setSelectedTrendId] = useState(null);
  const [selectedTrend, setSelectedTrend] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchTrendsItems = async () => {
      try {
        const trendsResponse = await axios.get('/api/trend');
        const sortedTrendsItems = trendsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const uniqueCategories = [...new Set(trendsResponse.data.flatMap(item => item.categories.split(',')))];
        setCategories(['All', ...uniqueCategories]);
        setTrendsItems(sortedTrendsItems);
        setFilteredTrendsItems(sortedTrendsItems);
      } catch (error) {
        console.error('Error fetching trends items:', error);
      }
    };
    fetchTrendsItems();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterTrendsItems(category, keyword, startDate, endDate);
    setCurrentPage(1);
    handleCloseMore();
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    filterTrendsItems(selectedCategory, event.target.value, startDate, endDate);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterTrendsItems(selectedCategory, keyword, start, end);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSelectedCategory('All');
    setKeyword('');
    setStartDate('');
    setEndDate('');
    setFilteredTrendsItems(trendsItems);
    setCurrentPage(1);
  };

  const filterTrendsItems = (category, keyword, start, end) => {
    let filteredItems = trendsItems;

    if (category !== 'All') {
      filteredItems = filteredItems.filter((trendsItem) =>
        trendsItem.categories.split(',').includes(category)
      );
    }

    if (keyword) {
      filteredItems = filteredItems.filter((trendsItem) =>
        trendsItem.name.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (start && end) {
      const startDateObj = new Date(start);
      const endDateObj = new Date(end);
      filteredItems = filteredItems.filter((trendsItem) => {
        const trendsDate = new Date(trendsItem.date);
        return trendsDate >= startDateObj && trendsDate <= endDateObj;
      });
    }

    setFilteredTrendsItems(filteredItems);
  };

  const handleEdit = (trendId) => {
    const trendToEdit = trendsItems.find(item => item.id === trendId);
    setSelectedTrend(trendToEdit);  // Ensure this is correctly set
    setIsEditModalOpen(true);  // Open the modal
    handleCloseMore();
  };
  

  
  const handleOpenDeleteDialog = (trendId) => {
 
    setSelectedTrendId(trendId);  // Set the selectedTrendId state
    setIsDeleteDialogOpen(true);  // Then open the dialog
  };
  
  const handleDelete = async () => {
 
    if (!selectedTrendId) {
      console.error("No trend ID selected for deletion");
      return;
    }
    try {
      await axios.delete(`/api/trend/${selectedTrendId}`);
      const updatedTrends = trendsItems.filter(item => item.id !== selectedTrendId);
      setTrendsItems(updatedTrends);
      setFilteredTrendsItems(updatedTrends);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting trend item:', error);
    }
  };
  
  
  
  const handleOpenMore = (event, trendId) => {
    setAnchorEl(event.currentTarget);
    setSelectedTrendId(trendId);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setSelectedTrendId(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleUpdate = () => {
    const fetchTrendsItems = async () => {
      try {
        const trendsResponse = await axios.get('/api/trend');
        
        // Sort trends by the 'date' field in descending order
        const sortedTrendsItems = trendsResponse.data.sort((a, b) => {
          const dateA = new Date(a.date); // Parse the 'date' field to Date object
          const dateB = new Date(b.date); // Parse the 'date' field to Date object
          return dateB - dateA; // Sort by date in descending order (newest first)
        });
        
        // Update state with the sorted trends
        setTrendsItems(sortedTrendsItems);
        setFilteredTrendsItems(sortedTrendsItems);
        
      } catch (error) {
        console.error('Error fetching trends items:', error);
      }
    };
  
    // Fetch and update the trends list after the update
    fetchTrendsItems();
  };
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTrendsItems.slice(indexOfFirstItem, indexOfLastItem);

  const getCategoryStyles = (category) => {
    if (category.includes('National')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d00, #006600)',  // Dark green gradient
        chipBackground: '#006600',
        color: '#FFFFFF',
      };
    }
    if (category.includes('Regional')) {
      return {
        cardBackground: 'linear-gradient(135deg, #004d4d, #008080)',  // Teal gradient
        chipBackground: '#008080',  // Teal background for the chip
        color: '#FFFFFF',  // White text color
      };
    }
    if (category.includes('International')) {
      return {
        cardBackground: 'linear-gradient(135deg, #000000, #434343)',  // Black gradient
        chipBackground: '#434343',
        color: '#FFFFFF',
      };
    }
    return {
      cardBackground: 'linear-gradient(135deg, #434343, #000000)',  // Default black gradient
      chipBackground: '#434343',
      color: '#FFFFFF',
    };
  };

  const renderMedia = (media) => {
    const mediaType = media.split('.').pop().toLowerCase();
    const isVideo = ['mp4', 'webm', 'ogg'].includes(mediaType);

    if (isVideo) {
      return (
        <video
          height="200"
          controls
          style={{ borderRadius: '16px 16px 0 0', width: '100%' }}
        >
          <source src={`https://smmosaic.com/uploads/${media}`} type={`video/${mediaType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <CardMedia
          component="img"
          height="200"
          image={`https://smmosaic.com/uploads/${media}`}
          alt="trend media"
          sx={{
            borderRadius: '16px 16px 0 0',
          }}
        />
      );
    }
  };

  return (
    <>
      <Container sx={{ marginTop: '5%', pb: '5%', position: 'relative', minHeight: '80vh' }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <TrendingUpIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
           Trend Management
          </Typography>
        </Box>

        <Paper elevation={6} sx={{ p: 3, mb: 4, backgroundColor: '#1b1b1b', borderRadius: '16px' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#FFD700' }}>Filters</Typography>
          <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={3}>
            <Stack direction="row" spacing={2}>
              {categories.slice(0, 3).map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onClick={() => handleCategoryChange(category)}
                  sx={{
                    backgroundColor: selectedCategory === category
                      ? getCategoryStyles(category).chipBackground
                      : '#333',
                    color: selectedCategory === category
                      ? getCategoryStyles(category).color
                      : '#FFD700',
                    fontWeight: selectedCategory === category ? 'bold' : 'normal',
                    cursor: 'pointer',
                    borderRadius: '8px',
                    px: 2,
                    py: 1,
                  }}
                />
              ))}
            </Stack>
            <TextField
              label="Search by keyword"
              variant="outlined"
              value={keyword}
              onChange={handleKeywordChange}
              sx={{
                width: '300px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => handleDateChange(e.target.value, endDate)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => handleDateChange(startDate, e.target.value)}
              sx={{
                width: '200px',
                backgroundColor: '#2b2b2b',
                borderRadius: '8px',
                input: { color: '#FFD700' },
                label: { color: '#FFD700' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#FFD700',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFA500',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFD700',
                  },
                },
              }}
            />
            <Button
              variant="contained"
              onClick={clearFilters}
              sx={{
                backgroundColor: '#333',
                color: '#FFD700',
                borderRadius: '8px',
                px: 3,
                py: 1,
                '&:hover': {
                  backgroundColor: '#444',
                },
              }}
            >
              Clear Filters
            </Button>
          </Box>
        </Paper>

        <Grid container spacing={4}>
          {currentItems.map((trendsItem) => (
            <Grid item xs={12} sm={6} md={4} key={trendsItem.id}>
            <Card
              sx={{
                transition: 'transform 0.3s ease-in-out',
                background: getCategoryStyles(trendsItem.categories).cardBackground,
                color: getCategoryStyles(trendsItem.categories).color,
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                },
                borderRadius: '16px',
                height: '350px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ position: 'relative', flex: '1 0 auto' }}>
                {renderMedia(trendsItem.media.split(',')[0])}
                <Chip
                  label={trendsItem.categories}
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: getCategoryStyles(trendsItem.categories).chipBackground,
                    color: getCategoryStyles(trendsItem.categories).color,
                    fontWeight: 'bold',
                  }}
                />
              </Box>
              <CardContent sx={{ flex: '0 1 auto' }}>
                <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: '1rem', fontWeight: 'bold', lineHeight: '1.2em' }}>
                  {trendsItem.name}
                </Typography>
              </CardContent>
          
              {/* Three-dot menu for Edit/Delete positioned horizontally */}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                <IconButton
                  sx={{ color: '#FFD700' }}
                  onClick={(e) => handleOpenMore(e, trendsItem.id)}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedTrendId === trendsItem.id}
                  onClose={handleCloseMore}
                  PaperProps={{
                    style: {
                      backgroundColor: '#333',
                      color: '#FFD700',
                    },
                  }}
                >
                  <MenuItem onClick={() => handleEdit(trendsItem.id)}>
                    <EditIcon sx={{ mr: 1 }} /> Edit
                  </MenuItem>
                  <MenuItem onClick={() => handleOpenDeleteDialog(trendsItem.id)}>
                    <DeleteIcon sx={{ mr: 1 }} /> Delete
                  </MenuItem>
                </Menu>
              </Box>
            </Card>
          </Grid>
          ))}
        </Grid>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
        }}>
          <Pagination
            count={Math.ceil(filteredTrendsItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            renderItem={(item) => {
              let label;
              let icon;

              switch (item.type) {
                case 'first':
                  label = 'First';
                  icon = <FirstPage sx={{ color: 'white' }} />;
                  break;
                case 'previous':
                  label = 'Previous';
                  icon = <ArrowBack sx={{ color: 'white' }} />;
                  break;
                case 'next':
                  label = 'Next';
                  icon = <ArrowForward sx={{ color: 'white' }} />;
                  break;
                case 'last':
                  label = 'Last';
                  icon = <LastPage sx={{ color: 'white' }} />;
                  break;
                default:
                  label = item.page; // For number pages
                  break;
              }

              return (
                <PaginationItem
                  {...item}
                  sx={{
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    '&.MuiPaginationItem-root': {
                      color: 'white',
                    },
                  }}
                  label={
                    <>
                      {icon}
                      <span style={{ marginLeft: '4px', color: 'white' }}>{label}</span>
                    </>
                  }
                />
              );
            }}
          />
        </Box>
      </Container>

{/* Edit Trend Modal */}
{selectedTrend && (
  <Dialog 
    open={isEditModalOpen}  // This controls if the modal is open or not
    onClose={() => setIsEditModalOpen(false)} // Handles closing the dialog
    maxWidth="sm" 
    fullWidth
  >
    <EditTrendForm 
      open={isEditModalOpen}  // Ensure this prop is passed
      onClose={() => setIsEditModalOpen(false)} // Corrected prop name to onClose
      trendId={selectedTrend.id}  // Pass the trendId to the form
      handleUpdate={handleUpdate} 
    />
  </Dialog>
)}



      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
  <DialogTitle>Delete Trend</DialogTitle>
  <DialogContent>Are you sure you want to delete this trend?</DialogContent>
  <DialogActions>
  <Button onClick={() => setIsDeleteDialogOpen(false)} color="secondary">
    Cancel
  </Button>
  <Button onClick={() => handleDelete(selectedTrendId)} color="primary">  {/* Pass the trendId directly */}
    Delete
  </Button>
</DialogActions>

</Dialog>
    </>
  );
};

export default TrendAdminPage;
