import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Box, Chip, Stack, Pagination, PaginationItem, IconButton, TextField, Button, Paper, Menu, MenuItem, Dialog , DialogTitle, DialogContent, DialogActions} from '@mui/material';
import { Newspaper as NewspaperIcon, MoreVert, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { FirstPage, LastPage, ArrowBack, ArrowForward } from '@mui/icons-material';
import EditNewsForm from './EditNewsForm';  // Import the edit form component


const NewsList = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [filteredNewsItems, setFilteredNewsItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [keyword, setKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);  // For holding the selected news for editing
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);  // Edit modal state
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);  // Delete confirmation dialog state
  

  useEffect(() => {
    const fetchNewsItems = async () => {
      try {
        const newsResponse = await axios.get('/api/news');
        const sortedNewsItems = newsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        const uniqueCategories = [...new Set(newsResponse.data.flatMap(item => item.categories.split(',')))];
        setCategories(['All', ...uniqueCategories]);
        setNewsItems(sortedNewsItems);
        setFilteredNewsItems(sortedNewsItems);
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };
    fetchNewsItems();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterNewsItems(category, keyword, startDate, endDate);
    setCurrentPage(1);
    handleCloseMore();
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    filterNewsItems(selectedCategory, event.target.value, startDate, endDate);
    setCurrentPage(1);
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    filterNewsItems(selectedCategory, keyword, start, end);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSelectedCategory('All');
    setKeyword('');
    setStartDate('');
    setEndDate('');
    setFilteredNewsItems(newsItems);
    setCurrentPage(1);
  };

  const filterNewsItems = (category, keyword, start, end) => {
    let filteredItems = newsItems;

    if (category !== 'All') {
      filteredItems = filteredItems.filter((newsItem) =>
        newsItem.categories.split(',').includes(category)
      );
    }

    if (keyword) {
      filteredItems = filteredItems.filter((newsItem) =>
        newsItem.description.toLowerCase().includes(keyword.toLowerCase())
      );
    }

    if (start && end) {
      const startDateObj = new Date(start);
      const endDateObj = new Date(end);
      filteredItems = filteredItems.filter((newsItem) => {
        const newsDate = new Date(newsItem.date);
        return newsDate >= startDateObj && newsDate <= endDateObj;
      });
    }

    setFilteredNewsItems(filteredItems);
  };

  const handleEdit = (newsItemId) => {
    const newsToEdit = newsItems.find(item => item.id === newsItemId);
    setSelectedNews(newsToEdit);
    setIsEditModalOpen(true);  // Open edit modal
    handleCloseMore();
  };
  const handleOpenDeleteDialog = (newsItemId) => {
    console.log("Opening delete dialog for News ID:", newsItemId);  // Debugging log to ensure ID is correct
    setSelectedNewsId(newsItemId);  // Set the correct news item ID
    setIsDeleteDialogOpen(true);    // Open the delete confirmation dialog
  };
  
  const handleDelete = async () => {
    if (!selectedNewsId) {
      console.error("No news item ID selected for deletion.");
      return;
    }
    
    try {
      await axios.delete(`/api/news/${selectedNewsId}`);
      const updatedNews = newsItems.filter(item => item.id !== selectedNewsId);
      setNewsItems(updatedNews);
      setFilteredNewsItems(updatedNews);
      setIsDeleteDialogOpen(false);  // Close the delete confirmation dialog
    } catch (error) {
      console.error('Error deleting news item:', error);
    }
  };
  

  

 
  const handleOpenMore = (event, newsItemId) => {
    setAnchorEl(event.currentTarget);
    setSelectedNewsId(newsItemId);
  };

  const handleCloseMore = () => {
    setAnchorEl(null);
    setSelectedNewsId(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredNewsItems.slice(indexOfFirstItem, indexOfLastItem);

  const handleUpdate = () => {
    const fetchNewsItems = async () => {
      try {
        const newsResponse = await axios.get('/api/news');
        const sortedNewsItems = newsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setNewsItems(sortedNewsItems);
        setFilteredNewsItems(sortedNewsItems);
      } catch (error) {
        console.error('Error fetching news items:', error);
      }
    };
    fetchNewsItems();
  };

  return (
    <Container sx={{ marginTop: '5%', pb: '5%', position: 'relative', minHeight: '80vh' }}>
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <NewspaperIcon sx={{ mr: 2, fontSize: '2rem', color: '#FFD700' }} />
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#FFD700', fontWeight: 'bold', letterSpacing: '2px', textTransform: 'uppercase' }}>
          News Management
        </Typography>
      </Box>

      <Paper elevation={6} sx={{ p: 3, mb: 4, backgroundColor: '#1b1b1b', borderRadius: '16px' }}>
        <Typography variant="h6" gutterBottom sx={{ color: '#FFD700' }}>Filters</Typography>
        <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={3}>
          <Stack direction="row" spacing={2}>
            {categories.slice(0, 3).map((category) => (
              <Chip
                key={category}
                label={category}
                onClick={() => handleCategoryChange(category)}
                sx={{
                  backgroundColor: selectedCategory === category ? '#FFD700' : '#333',
                  color: selectedCategory === category ? '#000' : '#FFD700',
                  fontWeight: selectedCategory === category ? 'bold' : 'normal',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  px: 2,
                  py: 1,
                }}
              />
            ))}
          </Stack>
          <TextField
            label="Search by keyword"
            variant="outlined"
            value={keyword}
            onChange={handleKeywordChange}
            sx={{
              width: '300px',
              backgroundColor: '#2b2b2b',
              borderRadius: '8px',
              input: { color: '#FFD700' },
              label: { color: '#FFD700' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFD700',
                },
                '&:hover fieldset': {
                  borderColor: '#FFA500',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFD700',
                },
              },
            }}
          />
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => handleDateChange(e.target.value, endDate)}
            sx={{
              width: '200px',
              backgroundColor: '#2b2b2b',
              borderRadius: '8px',
              input: { color: '#FFD700' },
              label: { color: '#FFD700' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFD700',
                },
                '&:hover fieldset': {
                  borderColor: '#FFA500',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFD700',
                },
              },
            }}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => handleDateChange(startDate, e.target.value)}
            sx={{
              width: '200px',
              backgroundColor: '#2b2b2b',
              borderRadius: '8px',
              input: { color: '#FFD700' },
              label: { color: '#FFD700' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFD700',
                },
                '&:hover fieldset': {
                  borderColor: '#FFA500',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFD700',
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={clearFilters}
            sx={{
              backgroundColor: '#333',
              color: '#FFD700',
              borderRadius: '8px',
              px: 3,
              py: 1,
              '&:hover': {
                backgroundColor: '#444',
              },
            }}
          >
            Clear Filters
          </Button>
        </Box>
      </Paper>

      <Grid container spacing={4}>
        {currentItems.map((newsItem) => (
          <Grid item xs={12} sm={6} md={4} key={newsItem.id}>
            <Card
              sx={{
                transition: 'transform 0.3s ease-in-out',
                background: '#1b1b1b',
                color: '#FFD700',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.5)',
                },
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box sx={{ flex: '1 0 auto' }}>
                {newsItem.media.includes('.mp4') ? (
                  <video
                    controls
                    style={{ borderRadius: '16px 16px 0 0', width: '100%', height: '200px' }}
                  >
                    <source src={`https://smmosaic.com/uploads/${newsItem.media.split(',')[0]}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <CardMedia
                    component="img"
                    height="200"
                    image={`https://smmosaic.com/uploads/${newsItem.media.split(',')[0]}`}
                    alt="news media"
                    sx={{ borderRadius: '16px 16px 0 0' }}
                  />
                )}
                <Chip
                  label={newsItem.categories}
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: '#FFD700',
                    color: '#000',
                    fontWeight: 'bold',
                  }}
                />
              </Box>
              <CardContent>
                <Typography variant="body2" color="textSecondary" sx={{ color: '#FFD700' }}>
                  {newsItem.description.length > 80 ? `${newsItem.description.substring(0, 80)}...` : newsItem.description}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '8px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  <Chip
                    label={newsItem.credibility}
                    sx={{
                      backgroundColor:
                        newsItem.credibility === 'High' ? '#800000' :
                        newsItem.credibility === 'Medium' ? '#FF8C00' : '#006400',
                      color: 'white',
                      fontWeight: 'bold',
                      borderRadius: '12px',
                      padding: '6px 12px',
                      fontSize: '0.85rem',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    }}
                  />
                 <Typography sx={{ color: 'white', textAlign: 'right' }}>
                    {new Date(newsItem.date).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    })}
                  </Typography>

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
                  <IconButton
                    sx={{ color: '#FFD700' }}
                    onClick={(e) => handleOpenMore(e, newsItem.id)}
                  >
                    <MoreVert />
                  </IconButton>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedNewsId === newsItem.id}
                  onClose={handleCloseMore}
                  PaperProps={{
                    style: {
                      backgroundColor: '#333',
                      color: '#FFD700',
                    },
                  }}
                >
                  <MenuItem onClick={() => handleEdit(newsItem.id)}>
                    <EditIcon sx={{ mr: 1 }} /> Edit
                  </MenuItem>
                  <MenuItem onClick={() => handleOpenDeleteDialog(newsItem.id)}>
                    <DeleteIcon sx={{ mr: 1 }} /> Delete
                  </MenuItem>
                </Menu>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 4,
      }}>
        <Pagination
          count={Math.ceil(filteredNewsItems.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          renderItem={(item) => {
            let label;
            let icon;

            switch (item.type) {
              case 'first':
                label = 'First';
                icon = <FirstPage sx={{ color: 'white' }} />;
                break;
              case 'previous':
                label = 'Previous';
                icon = <ArrowBack sx={{ color: 'white' }} />;
                break;
              case 'next':
                label = 'Next';
                icon = <ArrowForward sx={{ color: 'white' }} />;
                break;
              case 'last':
                label = 'Last';
                icon = <LastPage sx={{ color: 'white' }} />;
                break;
              default:
                label = item.page;
                break;
            }

            return (
              <PaginationItem
                {...item}
                sx={{
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  '&.MuiPaginationItem-root': {
                    color: 'white',
                  },
                }}
                label={
                  <>
                    {icon}
                    <span style={{ marginLeft: '4px', color: 'white' }}>{label}</span>
                  </>
                }
              />
            );
          }}
        />
      </Box>

      {/* Edit News Modal */}
      {selectedNews && (
        <Dialog 
          open={isEditModalOpen} 
          onClose={() => setIsEditModalOpen(false)} 
          maxWidth="sm" 
          fullWidth
        >
          <EditNewsForm
            open={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            newsId={selectedNews.id}
            handleUpdate={handleUpdate}
          />
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
     {/* Delete Confirmation Dialog */}
<Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
  <DialogTitle>Delete News</DialogTitle>
  <DialogContent>Are you sure you want to delete this news?</DialogContent>
  <DialogActions>
    <Button onClick={() => setIsDeleteDialogOpen(false)} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleDelete} color="primary">  {/* Call handleDelete directly */}
      Delete
    </Button>
  </DialogActions>
</Dialog>

    </Container>
  );
};

export default NewsList;
