import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Paper, Typography, IconButton, CircularProgress, Fade, Divider } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TitleIcon from '@mui/icons-material/Title';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';

const FormContainer = styled(Paper)({
  padding: '32px',
  marginTop: '32px',
  backgroundColor: '#f5f5f5',
  borderRadius: '15px',
  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  overflowY: 'auto',
  maxHeight: '90vh',
  color: '#333',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTextField-root': {
    margin: '8px',
    backgroundColor: '#fff',
    borderRadius: '5px',
  },
  '& .MuiInputLabel-root': {
    color: '#333',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#888',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#333',
    },
  },
  '& .MuiButton-root': {
    margin: '24px 8px 8px',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '10px',
  },
});

const StyledButton = styled(Button)({
  color: 'white',
  '&:hover': {
    backgroundImage: 'linear-gradient(315deg, #ff9800, #ff5722)',
  },
  '&.Mui-disabled': {
    background: 'rgba(255, 152, 0, 0.5)',
  },
});

const PageContainer = styled(Paper)({
  padding: '16px',
  marginBottom: '24px',
  backgroundColor: '#cbcbcb',
  borderRadius: '10px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  color: '#333',
});

const SubheadingContainer = styled(Paper)({
  padding: '16px',
  marginBottom: '16px',
  backgroundColor: '#d0d0d0',
  borderRadius: '8px',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.5)',
  transition: 'all 0.3s ease-in-out',
  color: '#333',
  marginLeft: '75px',
  '&:hover': {
    transform: 'scale(1.01)',
  },
});

const SubSubheadingContainer = styled(Paper)({
  padding: '8px',
  marginBottom: '8px',
  backgroundColor: '#e0e0e0',
  borderRadius: '6px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.5)',
  transition: 'all 0.3s ease-in-out',
  color: '#333',
  marginLeft: '150px',
  '&:hover': {
    transform: 'scale(1.01)',
  },
});

const validateFile = (file) => {
  const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

  if (validImageTypes.includes(file.type)) {
    return true;
  }
  return false;
};

const ArticleForm = () => {
  const [article, setArticle] = useState({
    title: 'OSINT - Situational Awareness',
    date: '',
    description: '',
    image: null,
    imageName: '',
    pages: [],
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setArticle((prevState) => ({ ...prevState, date: currentDate }));
  }, []);

  const handleArticleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      if (!validateFile(files[0])) {
        toast.error('Invalid file type. Please upload a PNG, JPEG, JPG image.');
        return;
      }
    }

    setArticle({
      ...article,
      [name]: files ? files[0] : value,
      imageName: files ? files[0].name : article.imageName,
    });
  };

  const handlePageChange = (index, e) => {
    const updatedPages = [...article.pages];
    updatedPages[index] = { ...updatedPages[index], [e.target.name]: e.target.value };
    setArticle({ ...article, pages: updatedPages });
  };

  const handleSubheadingChange = (pageIndex, subIndex, e, field) => {
    const updatedPages = [...article.pages];
    if (e.target.files && e.target.files[0]) {
      if (!validateFile(e.target.files[0])) {
        toast.error('Invalid file type. Please upload a PNG, JPEG, JPG image.');
        return;
      }
    }
    updatedPages[pageIndex].subheadings[subIndex] = {
      ...updatedPages[pageIndex].subheadings[subIndex],
      [field]: e.target.files ? e.target.files[0] : e.target.value,
      [`${field}Name`]: e.target.files ? e.target.files[0].name : updatedPages[pageIndex].subheadings[subIndex][`${field}Name`],
    };
    setArticle({ ...article, pages: updatedPages });
  };

  const handleSubSubheadingChange = (pageIndex, subIndex, subSubIndex, e, field) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].subheadings[subIndex].subsubheadings[subSubIndex] = {
      ...updatedPages[pageIndex].subheadings[subIndex].subsubheadings[subSubIndex],
      [field]: e.target.value,
    };
    setArticle({ ...article, pages: updatedPages });
  };

  const handleReferenceChange = (pageIndex, referenceIndex, e) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].references[referenceIndex] = e.target.value;
    setArticle({ ...article, pages: updatedPages });
  };

  const addReference = (pageIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].references = updatedPages[pageIndex].references || [];
    updatedPages[pageIndex].references.push('');
    setArticle({ ...article, pages: updatedPages });
  };

  const removeReference = (pageIndex, referenceIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].references = updatedPages[pageIndex].references.filter((_, i) => i !== referenceIndex);
    setArticle({ ...article, pages: updatedPages });
  };

  const addPage = () => {
    setArticle({
      ...article,
      pages: [...article.pages, { mainHeading: '', subheadings: [{ title: '', image: null, imageName: '', subsubheadings: [] }], summary: '', references: [] }],
    });
  };

  const removePage = (index) => {
    const filteredPages = article.pages.filter((_, i) => i !== index);
    setArticle({ ...article, pages: filteredPages });
  };

  const addSubheading = (pageIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].subheadings.push({ title: '', image: null, imageName: '', subsubheadings: [] });
    setArticle({ ...article, pages: updatedPages });
  };

  const removeSubheading = (pageIndex, subIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].subheadings = updatedPages[pageIndex].subheadings.filter((_, i) => i !== subIndex);
    setArticle({ ...article, pages: updatedPages });
  };

  const addSubSubheading = (pageIndex, subIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].subheadings[subIndex].subsubheadings.push({ title: '' });
    setArticle({ ...article, pages: updatedPages });
  };

  const removeSubSubheading = (pageIndex, subIndex, subSubIndex) => {
    const updatedPages = [...article.pages];
    updatedPages[pageIndex].subheadings[subIndex].subsubheadings = updatedPages[pageIndex].subheadings[subIndex].subsubheadings.filter((_, i) => i !== subSubIndex);
    setArticle({ ...article, pages: updatedPages });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!article.title || !article.date) {
      toast.error('Please fill in all required fields.');
      return;
    }

    // Check if at least one page is added
    if (article.pages.length === 0) {
      toast.error('Please add at least one page.');
      return;
    }

    // Validate each page's fields
    if (article.pages.some(page => !page.mainHeading || !page.summary)) {
      toast.error('Please fill in all page details.');
      return;
    }

    if (article.pages.some(page => page.subheadings.some(sub => !sub.title))) {
      toast.error('All subheadings must have a title.');
      return;
    }

    // Check if each page has at least one reference
    if (article.pages.some(page => !page.references || page.references.length === 0)) {
      toast.error('Each page must have at least one reference.');
      return;
    }

    // If validation passes, proceed with sending data to backend
    setLoading(true);
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('date', article.date);
    formData.append('description', article.description);
    formData.append('image', article.image);

    article.pages.forEach((page, pageIndex) => {
      formData.append(`pages[${pageIndex}][mainHeading]`, page.mainHeading);
      formData.append(`pages[${pageIndex}][summary]`, page.summary);

      page.references.forEach((reference, refIndex) => {
        formData.append(`pages[${pageIndex}][references][${refIndex}]`, reference);
      });

      page.subheadings.forEach((sub, subIndex) => {
        formData.append(`pages[${pageIndex}][subheadings][${subIndex}][title]`, sub.title);
        formData.append(`subheadingImages[${pageIndex}][${subIndex}]`, sub.image);

        sub.subsubheadings.forEach((subSub, subSubIndex) => {
          formData.append(`pages[${pageIndex}][subheadings][${subIndex}][subsubheadings][${subSubIndex}][title]`, subSub.title);
        });
      });
    });

    try {
      await axios.post('/api/article/add', formData);
      toast.success('Article added successfully!');

      // Reset the form
      setArticle({
        title: 'OSINT - Situational Awareness',
        date: '',
        description: '',
        image: null,
        imageName: '',
        pages: [],
      });

      setSuccess(true);
    } catch (error) {
      toast.error('Error adding article.');
    } finally {
      setLoading(false);
      setTimeout(() => setSuccess(false), 3000);
    }
  };

  return (
    <FormContainer>
      <ToastContainer />
      <Typography variant="h4" gutterBottom color="#333" textAlign="center">
        <TitleIcon /> Add New Situational Awareness
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Article Title"
              fullWidth
              name="title"
              value={article.title}
              onChange={handleArticleChange}
              required
              InputProps={{
                startAdornment: <TitleIcon color="action" />,
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="date"
              label="Date"
              fullWidth
              name="date"
              value={article.date}
              onChange={handleArticleChange}
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <DateRangeIcon color="action" />,
                readOnly: true,
              }}
            />
          </Grid>

          {article.pages.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              <Divider style={{ margin: '20px 0' }} />
              <PageContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Main Slide Heading"
                      fullWidth
                      name="mainHeading"
                      value={page.mainHeading}
                      onChange={(e) => handlePageChange(pageIndex, e)}
                      required
                    />
                  </Grid>
                  {page.subheadings.map((sub, subIndex) => (
                    <SubheadingContainer key={subIndex}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <TextField
                            label="Side Bullet Text"
                            fullWidth
                            name="title"
                            value={sub.title}
                            onChange={(e) => handleSubheadingChange(pageIndex, subIndex, e, 'title')}
                            required
                          />
                          <Button variant="contained" component="label" startIcon={<ImageIcon />}>
                            Upload Side Bullet Image
                            <input type="file" name={`subheadingImages[${pageIndex}][${subIndex}]`} hidden onChange={(e) => handleSubheadingChange(pageIndex, subIndex, e, 'image')} />
                          </Button>
                          {sub.imageName && <Typography variant="body2" color="textSecondary">{sub.imageName}</Typography>}
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={() => removeSubheading(pageIndex, subIndex)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {sub.subsubheadings.map((subSub, subSubIndex) => (
                        <SubSubheadingContainer key={subSubIndex}>
                          <Grid container spacing={2}>
                            <Grid item xs={10}>
                              <TextField
                                label="Sub Bullet Text"
                                fullWidth
                                name="title"
                                value={subSub.title}
                                onChange={(e) => handleSubSubheadingChange(pageIndex, subIndex, subSubIndex, e, 'title')}
                                required
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton onClick={() => removeSubSubheading(pageIndex, subIndex, subSubIndex)}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </SubSubheadingContainer>
                      ))}
                      <Button startIcon={<AddIcon />} onClick={() => addSubSubheading(pageIndex, subIndex)}>
                        Add Sub-subheading
                      </Button>
                    </SubheadingContainer>
                  ))}
                  <Grid item xs={12}>
                    <Button startIcon={<AddIcon />} onClick={() => addSubheading(pageIndex)}>
                      Add Subheading
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Summary"
                      fullWidth
                      multiline
                      rows={3}
                      name="summary"
                      value={page.summary}
                      onChange={(e) => handlePageChange(pageIndex, e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">References</Typography>
                    {page.references && page.references.map((reference, refIndex) => (
                      <Grid container key={refIndex} spacing={2} alignItems="center">
                        <Grid item xs={10}>
                          <TextField
                            label={`Reference ${refIndex + 1}`}
                            fullWidth
                            value={reference}
                            onChange={(e) => handleReferenceChange(pageIndex, refIndex, e)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton onClick={() => removeReference(pageIndex, refIndex)}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                    <Button startIcon={<AddIcon />} onClick={() => addReference(pageIndex)}>
                      Add Reference
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <IconButton onClick={() => removePage(pageIndex)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </PageContainer>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button startIcon={<AddIcon />} onClick={addPage}>
              Add Page
            </Button>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              type="submit"
              variant="contained"
              disabled={loading}
              fullWidth
              endIcon={loading ? <CircularProgress size={24} /> : <SaveIcon />}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      {success && (
        <Fade in={success}>
          <Typography variant="h6" sx={{ color: '#388e3c', mt: 2 }}>
            <CheckCircleOutlineIcon /> Article added successfully!
          </Typography>
        </Fade>
      )}
    </FormContainer>
  );
};

export default ArticleForm;
